import { TurboMountController } from "turbo-mount";

export default class extends TurboMountController {
  static targets = ["mount", "form"];

  get componentProps() {
    console.log(this.propsValue)
    return {
      ...this.propsValue,
      onChange: this.onChange,
      onSave: this.onSave
    };
    
  }

  onChange = (events) => {
    // Clear any existing weekly_availabilities_attributes inputs
    this.formTarget.querySelectorAll('input[name^="availability_request[weekly_availabilities_attributes]"]').forEach(el => el.remove());

    // Create new inputs for each event
    events.forEach((event, index) => {
      const prefix = `availability_request[weekly_availabilities_attributes][${index}]`;

      if(event.extendedProps.origin === 'server') {
        this.createHiddenInput(`${prefix}[id]`, event.id);
      }
      // this.createHiddenInput(`${prefix}[location]`, event.extendedProps.eventType);
      this.createHiddenInput(`${prefix}[start_time]`, this.formatTime(event.start));
      this.createHiddenInput(`${prefix}[end_time]`, this.formatTime(event.end));
      this.createHiddenInput(`${prefix}[wday]`, event.start.getDay());
      this.createHiddenInput(`${prefix}[location]`, event.extendedProps.eventType);
      // this.createHiddenInput(`${prefix}[all_day]`, event.allDay);
      this.createHiddenInput(`${prefix}[_destroy]`, event.extendedProps._destroy === '1' ? '1' : '0');
    });
    // Submit the form using Turbo
    // this.formTarget.requestSubmit();

    // Update the component props
    this.setComponentProps({ ...this.propsValue, events });
  };

  onSave = (events) => {
    // Submit the form using Turbo
    this.formTarget.requestSubmit();

    // Update the component props
    this.setComponentProps({ ...this.propsValue, events });
  };

  createHiddenInput(name, value) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    this.formTarget.appendChild(input);
  }

  formatTime(date) {
    return date.toTimeString().slice(0, 5); // Returns time in HH:MM format
  }
}