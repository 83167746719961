import React, {useState, useMemo, useEffect, useRef, useCallback} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import bootstrap5Plugin from "@fullcalendar/bootstrap5"
import interactionPlugin from '@fullcalendar/interaction'

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table'

const locationOptions = [
  { label: "Zoom", value: "zoom" },
  { label: "Onsite", value: "onsite" }
];

const SelectedFilterPill = ({ selectedValues, options }) => {
  console.log(selectedValues)
  const size = new Set(selectedValues || [])
  const selectedValuesArr = new Set(selectedValues)
  if (size.size > 2) {
    return <span className="badge text-bg-light m-0 py-0">{size.size}</span>
  }
  
  return (
    <>
      { selectedValues &&
        options
          .filter(option => selectedValuesArr.has(option.value))
          .map(option => (
            <span className="badge text-bg-light m-0 py-0 ms-1" key={option.value}>{option.label}</span>
          ))
      }
    </>
  )
}

function DataTableFacetedFilter({column, title, options }) {
  const facets = column?.getFacetedUniqueValues()
  const selectedValues = new Set(column?.getFilterValue() || [])

  const handleOptionToggle = useCallback((value) => {
    const updatedSelectedValues = new Set(selectedValues)
    if (updatedSelectedValues.has(value)) {
      updatedSelectedValues.delete(value)
    } else {
      updatedSelectedValues.add(value)
    }
    column?.setFilterValue(Array.from(updatedSelectedValues))
  }, [selectedValues, column])

  return (
    <div className="dropdown">
      <button className="btn btn-neutral btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <i className="bi bi-plus-circle me-2"></i>
        {title} <SelectedFilterPill selectedValues={column?.getFilterValue()} options={options} />
      </button>
      <ul className="dropdown-menu p-2" style={{minWidth: '200px'}} onClick={(event) => event.stopPropagation()}>
        {options.map((option) => {
          const isSelected = selectedValues.has(option.value)
          return (
            <li key={option.value} className="mb-1">
              <div
                className={`cursor-pointer dropdown-item ${isSelected ? 'active' : ''}`}
                onClick={() => handleOptionToggle(option.value)}
              >
                <i className={`me-2 ${isSelected ? 'bi bi-check-square-fill' : 'bi bi-square'}`}></i>
                {option.label}
                {facets?.get(option.value) && (
                  <span className="badge bg-secondary ms-1">{facets.get(option.value)}</span>
                )}
              </div>
            </li>
          )
        })}
        {selectedValues.size > 0 && (
          <>
            <li><hr className="dropdown-divider" /></li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => column?.setFilterValue(undefined)}
              >
                Clear filters
              </button>
            </li>
          </>
        )}
      </ul>
    </div>
  )
}

function DataTableToolbar({ table }) {
  const isFiltered = table.getState().columnFilters.length > 0

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center gap-2">
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="Filter courses..."
          value={(table.getColumn('title')?.getFilterValue()) ?? ''}
          onChange={(event) => table.getColumn('title')?.setFilterValue(event.target.value)}
          style={{width: '200px'}}
        />
        {table.getColumn('location') && (
          <DataTableFacetedFilter
            column={table.getColumn('location')}
            title="Location"
            options={locationOptions}
          />
        )}
        {isFiltered && (
          <button
            className="btn btn-link btn-sm"
            onClick={() => table.resetColumnFilters()}
          >
            Reset
          </button>
        )}
      </div>
    </div>
  )
}

export default function Calendar({resources, events, firstDate, teacherAvailabilities}) {
  console.log(teacherAvailabilities)
  const columns = useMemo(() => [
    {
      id: 'title',
      accessorFn: (row) => row.title,
      header: 'Title',
    },
    {
      id: 'location',
      accessorFn: (row) => row.extendedProps?.location?.toLowerCase(),
      header: 'Location',
      filterFn: "arrIncludesSome"
    },
  ], [])

  const table = useReactTable({
    data: events,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const filteredEvents = [...teacherAvailabilities, ...table.getFilteredRowModel().rows.map(row => row.original)]

  return (
    <>
      <div className="container-lg bg-white rounded shadow-1 border-2 p-2 shadow-2 mb-3">
        <DataTableToolbar table={table} />
      </div>

      <div className="bg-white">
        <FullCalendar
          plugins={[dayGridPlugin, resourceTimelinePlugin, bootstrap5Plugin, interactionPlugin]}
          themeSystem={'bootstrap5'}
          initialView="resourceTimeline"
          headerToolbar={false}
          initialDate={firstDate}
          views={{
            resourceTimeline: {
              type: 'resourceTimeline',
              duration: {days: 7},
              hiddenDays: [0, 6],
              resourceGroupLabelContent: 'title',
              resourceAreaWidth: '10%',
            }
          }}
          slotMinTime={'10:00:00'}
          slotMaxTime={'20:00:00'}
          slotDuration={'00:15:00'}
          resources={resources}
          events={filteredEvents}
          eventContent={renderEventContent}
        />
      </div>
    </>
  )
}

function renderEventContent(eventInfo) {
  console.log(eventInfo)
  return(
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}