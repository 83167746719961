import "@hotwired/turbo-rails"
import "../controllers"
import "../turbo-mount"

import "trix"
import "@rails/actiontext"

import "../config"
import "../channels"

Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

import Dropdown from 'bootstrap/js/dist/dropdown'
// import Accordion from 'bootstrap/js/dist/ac'
import Collapse from 'bootstrap/js/dist/collapse'