import { Controller } from "@hotwired/stimulus"
import CalendarController from "./calendar_controller";

import { get, post, put, patch, destroy } from '@rails/request.js'

// Connects to data-controller="availabilities"
export default class extends CalendarController {

  static values = { events: Array, config: Object }
  static targets = [ "form" ]

  initialize() {
    console.log('CONNECT')
    let config = {
      editable: true,
      eventClick: (info) => {
        info.jsEvent.preventDefault()
        console.log('event click')
        Turbo.visit(info.event.url, { frame: 'modal' })
      },
      eventDrop: (event) => {
        this.submitForm(event.event.url, event.event)
      },
      eventResize: (event) => {
        this.submitForm(event.event.url, event.event)
      }
    }
    this.config = Object.assign( this.configValue, config )
    super.connect()
  }

  reconnect() {
    this.calendar.destroy()
    this.initialize()
  }

  disconnect() {
    console.log('DISCONNECT')
    super.disconnect()
  }

  select(event) {
    console.log(event)
    // // this.calendar.addEvent({ title: 'dynamic event', start: event.start, end: event.end, allDay: event.allDay})
    // const formData = new FormData();
    // formData.append('availability[starts_at]', event.start)
    // formData.append('availability[ends_at]', event.end)
    // formData.append('availability[session_id]', 1)
    // // post('/availabilities', {
    // //     body: formData,
    // //     responseKind: 'turbo-stream'
    // // })
    // document.getElementById('availability_starts_at').value = event.start
    // document.getElementById('availability_ends_at').value = event.end
    // document.getElementById('availability_session_id').value = 1
    // console.log(this.formTarget)
    // this.formTarget.requestSubmit()
  }

  submitForm(action, event) {
    this.formTarget.action = action
    this.formTarget.method = 'PATCH'
    document.getElementById('course_first_datetime').value = event.start
    // document.getElementById('availability_ends_at').value = event.end
    // document.getElementById('availability_session_id').value = 1
    this.formTarget.requestSubmit()
  }
}
