import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic"
export default class extends Controller {
  static targets = ["select"]
  static values = {
    url: String,
    param: String
  }

  connect() {
    console.log('connected')
  }

  submit() {
    console.log('foo')
    this.element.requestSubmit()
  }
}
