import React, {useState, useEffect, useRef, useCallback, useMemo} from 'react';
import FullCalendar from '@fullcalendar/react';
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { BootstrapTheme } from "@fullcalendar/bootstrap5/internal";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId
} from "@floating-ui/react";

// Custom hook for managing context menu
const useContextMenu = () => {
  const [activeEvent, setActiveEvent] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [activeEventId, setActiveEventId] = useState(null);
  const [isClosingContextMenu, setIsClosingContextMenu] = useState(false);
  const timeoutRef = useRef(null);
  const clickTimeRef = useRef(0);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      setIsOpen(open);
      if (!open) {
        setIsClosingContextMenu(true);
        clickTimeRef.current = Date.now();
        timeoutRef.current = setTimeout(() => {
          setIsClosingContextMenu(false);
        }, 300);
      }
    },
    middleware: [offset(10), flip({ fallbackAxisSideDirection: "end" }), shift()],
    whileElementsMounted: autoUpdate
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const headingId = useId();

  const openContextMenu = useCallback((eventId, element, activeEvent) => {
    setActiveEvent(activeEvent)
    setActiveEventId(eventId);
    refs.setPositionReference(element);
    setIsOpen(true);
  }, [refs]);

  const shouldPreventEventCreation = useCallback(() => {
    return isOpen || isClosingContextMenu || (Date.now() - clickTimeRef.current < 300);
  }, [isOpen, isClosingContextMenu]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return {
    isOpen,
    activeEvent,
    activeEventId,
    isClosingContextMenu,
    openContextMenu,
    refs,
    context,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    headingId,
    shouldPreventEventCreation,
    setIsOpen
  };
};

const useCalendarEvents = (initialEvents) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    setEvents(initialEvents.map(parseEvent));
  }, [initialEvents]);

  const updateEvent = useCallback((eventId, updates) => {
    setEvents(prevEvents =>
      prevEvents.map(event =>
        event.id === eventId
          ? {
            ...event,
            ...updates,
            extendedProps: {
              ...event.extendedProps,
              ...updates.extendedProps
            }
          }
          : event
      )
    );
  }, []);

  const persistUpdate = (e) => {
    console.log('persisting')
    console.log(e.event)
    const { id, title, start, end, allDay, extendedProps } = e.event;
    updateEvent(id, {
      title,
      start,
      end,
      allDay,
      extendedProps
    });
  }

  return { events, setEvents,  updateEvent, persistUpdate };

}

const renderEventColor = (eventType) => {
  switch(eventType) {
    case 'zoom':
      return window.getComputedStyle(document.documentElement).getPropertyValue('--bs-green');
    case 'onsite':
      return window.getComputedStyle(document.documentElement).getPropertyValue('--bs-blue');
    default:
      return window.getComputedStyle(document.documentElement).getPropertyValue('--bs-blue');
  }
}

const renderEventColorAndBorderColor = (eventType) => {
  switch (eventType) {
    case 'zoom':
      return {
        color: window.getComputedStyle(document.documentElement).getPropertyValue('--bs-green-200'),
        borderColor: window.getComputedStyle(document.documentElement).getPropertyValue('--bs-green-500'),
        textColor: 'black'
      };
    case 'onsite':
      return {
        color: window.getComputedStyle(document.documentElement).getPropertyValue('--bs-purple-200'),
        borderColor: window.getComputedStyle(document.documentElement).getPropertyValue('--bs-purple-500'),
        textColor: 'black'
      };
    default:
      return {
        color: window.getComputedStyle(document.documentElement).getPropertyValue('--bs-blue-200'),
        borderColor: window.getComputedStyle(document.documentElement).getPropertyValue('--bs-blue-500'),
        textColor: 'black'
      };
  }
}

// ContextMenu component
const ContextMenu = ({ context, refs, activeEvent, floatingStyles, getFloatingProps, headingId, updateEvent, activeEventId, setIsOpen }) => {
  if (!context.open) return null;

  const handleUpdateEventType = (eventType) => {
    console.log(eventType)
    updateEvent(activeEventId, {
      title: eventType,
      source: eventType,
      extendedProps: { ...activeEvent.extendedProps, eventType },
      ...renderEventColorAndBorderColor(eventType)
      // borderColor: eventType === 'zoom' ? '#4A5568' : '#2C5282'
    });
    setIsOpen(false);
  };

  const handleDelete = () => {
    console.log('delete')
    console.log(activeEvent)
    updateEvent(activeEventId, {
      ...activeEvent.extendedProps,
      extendedProps: { "_destroy": '1'}
    })
    setIsOpen(false);
  }

  return (
    <FloatingFocusManager context={context} modal={false} initialFocus={refs.floating}>
      <div
        ref={refs.setFloating}
        className="context-menu bg-white z-1 p-2 rounded shadow border"
        style={{...floatingStyles, transition: 'opacity 0.2s ease-in-out'}}
        aria-labelledby={headingId}
        {...getFloatingProps()}
      >
        <div className="btn-group">
          <button className={`btn btn-neutral btn-xs w-full ${activeEvent.extendedProps.eventType === 'onsite' ? 'active' : ''}`} onClick={() => handleUpdateEventType('onsite')}>
            Onsite
          </button>
          <button className={`btn btn-neutral btn-xs w-full ${activeEvent.extendedProps.eventType === 'zoom' ? 'active' : ''}`} onClick={() => handleUpdateEventType('zoom')}>
            Zoom
          </button>
        </div>
        <button className="btn btn-neutral btn-xs w-full ms-1" onClick={() => handleDelete()}>
          Delete
        </button>
      </div>
    </FloatingFocusManager>
  );
};

// Helper functions and configurations

const parseEvent = (event) => ({
  ...event,
  start: new Date(event.start),
  end: new Date(event.end),
  ...renderEventColorAndBorderColor(event.extendedProps.eventType)
  // color: renderEventColor(event.extendedProps.eventType)
});

const formatTime = (date) => date.toTimeString().slice(0, 5);

// Modify BootstrapTheme
BootstrapTheme.prototype.classes.button = 'btn btn-neutral btn-sm shadow-none';

// Main AvailabilityForm component
const AvailabilityForm = ({ initialEvents, onChange, firstDate, onSave, editable }) => {
  const calendarRef = useRef();
  const { events, setEvents, updateEvent, persistUpdate } = useCalendarEvents(initialEvents);
  const visibleEvents = useMemo(() => events.filter(e => e.extendedProps["_destroy"] !== '1'), [events]);

  const {
    isOpen,
    activeEvent,
    activeEventId,
    isClosingContextMenu,
    openContextMenu,
    refs,
    context,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    headingId,
    shouldPreventEventCreation,
    setIsOpen
  } = useContextMenu();

  useEffect(() => {
    onChange(events);
  }, [events, onChange]);

  const defaultLocation = useCallback(() => {
    console.log('Current events:', events);
    if (events.length === 0) return 'zoom';
    const sortedEvents = [...events].sort((a, b) => Number(b.id) - Number(a.id));
    const lastLocation = sortedEvents[0].extendedProps.eventType;
    return lastLocation === 'onsite' ? 'onsite' : 'zoom';
  }, [events]);

  const createNewEvent = useCallback((selectInfo) => ({
    id: Date.now().toString(),
    title: defaultLocation(),
    start: selectInfo.start,
    end: selectInfo.end,
    extendedProps: { eventType: defaultLocation() },
    ...renderEventColorAndBorderColor(defaultLocation())
  }), [defaultLocation]);

  const handleSelect = useCallback((selectInfo) => {
    if (shouldPreventEventCreation()) {
      selectInfo.view.calendar.unselect();
      return;
    }

    const newEvent = createNewEvent(selectInfo);
    setEvents(prevEvents => [...prevEvents, newEvent]);
  }, [shouldPreventEventCreation, createNewEvent, setEvents]);

  const handleEventClick = useCallback((clickInfo) => {
    openContextMenu(clickInfo.event.id, clickInfo.el, clickInfo.event);
    clickInfo.jsEvent.preventDefault();
  }, [openContextMenu]);

  const editProps = useMemo(() => {
    return editable ? {
      selectable: true,
      editable: true,
      eventResize: persistUpdate,
      eventDrop: persistUpdate,
      select: handleSelect,
      eventClick: handleEventClick
    } : {}
  }, [editable, persistUpdate, handleSelect, handleEventClick]);

  return (
    <div>
      <FullCalendar
        plugins={[timeGridPlugin, interactionPlugin, bootstrap5Plugin, interactionPlugin]}
        themeSystem="bootstrap5"
        initialView="timeGridWeek"
        weekends={false}
        dayMaxEvents={true}
        events={visibleEvents}
        headerToolbar={false}
        dayHeaderFormat={{weekday: 'long'}}
        allDaySlot={false}
        initialDate={firstDate}
        slotMinTime={'08:00:00'}
        slotMaxTime={'20:00:00'}
        contentHeight={'auto'}
        eventOverlap={false}
        selectOverlap={false}

        {...editProps}

        eventConstraint={{start: '00:00', end: '24:00'}}
      />
      <ContextMenu
        context={context}
        refs={refs}
        activeEvent={activeEvent}
        floatingStyles={floatingStyles}
        getFloatingProps={getFloatingProps}
        headingId={headingId}
        updateEvent={updateEvent}
        activeEventId={activeEventId}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default AvailabilityForm;